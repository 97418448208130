<template>
    <v-container fluid grid-list-xl class="mt-3 mb-8">
        <v-layout align-start wrap>
            <v-layout row>
                <v-flex xs12 md6 class="pt-0">
                    <v-card class="pa-4 mx-3 mb-3">
                        <div class="mb-4 mt-4">
                            <div class="login-title text-center">
                                ENTER YOUR INFO
                            </div>
                        </div>
                        <v-text-field
                            class="mb-2 mt-0"
                            label="Name"
                            v-model="manual.name"
                            :rules="manualRules"
                            color="#389466"
                            ref="name"
                        ></v-text-field>
                        <v-text-field
                            class="mb-2 mt-0"
                            label="Phone Number"
                            v-model="manual.phone"
                            color="#389466"
                            :rules="manualPhoneRules"
                            ref="phone"
                        ></v-text-field>
                        <v-text-field
                            class="mb-3 mt-0"
                            label="Address"
                            v-model="manual.address"
                            :rules="manualRules"
                            color="#389466"
                            ref="address"
                        ></v-text-field>
                        <v-text-field
                            class="mb-3 mt-0"
                            label="Email"
                            v-model="manual.email"
                            :rules="emailRules"
                            color="#389466"
                            ref="email"
                        ></v-text-field>
                    </v-card>
                    <!-- <v-card class="pa-4 mx-3">
                        <div class="mb-4 mt-4">
                            <div class="login-title text-center">
                                DEPARTMENT TO PAY
                            </div>
                        </div>
                        <v-select
                            class="px-2"
                            v-model="manual.selectedCampaign"
                            :items="campaigns"
                            label="Department"
                            ref="selectedCampaign"
                            item-text="displayName"
                            color="#389466"
                            item-value="id"
                            :rules="manualRules"
                            return-object
                            single-line
                        ></v-select>
                        <v-text-field
                            v-if="manual.selectedCampaign.name == 'Other'"
                            class="px-2"
                            label="Description"
                            ref="campaignReason"
                            color="#389466"
                            v-model="manual.campaignReason"
                            :rules="campaignReasonRules"
                        ></v-text-field>
                    </v-card> -->
                </v-flex>
                <v-flex xs12 md6>
                    <div class="mx-1">
                        <payment
                            :responsive="responsive"
                            ref="payment"
                            :donor="donor"
                            :manual="manual"
                            :validateUserInfo="validateUserInfo"
                            :manualPayment="manualPayment"
                            :amountMissing="amountStillMissing"
                            :campaigns="campaigns"
                            :selectionAmounts="selectionAmounts"
                            :noteTitle="noteTitle"
                        ></payment>
                    </div>
                </v-flex>
            </v-layout>
        </v-layout>
        <v-dialog v-model="recoverPinDialog" max-width="400">
            <v-card class="pa-2">
                <div v-if="recoverPinSuccess">
                    <v-card-text class="body-1"
                        >We will call you back with your pin
                        shortly.</v-card-text
                    >
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="default" @click="recoverPinDialog = false"
                            >Close</v-btn
                        >
                    </v-card-actions>
                </div>
                <div v-else>
                    <v-card-title class="pb-2">Recover Pin</v-card-title>
                    <v-card-text
                        >Please enter a phone number that we have on
                        file.</v-card-text
                    >
                    <v-text-field
                        class="px-4 pt-4 mb-3 mt-0"
                        label="Phone Number"
                        v-model="recoverPinPhone"
                        :rules="[
                            (v) => !!v || 'A Valid Phone Number Is Required.',
                            (v) =>
                                !v ||
                                ([10, 11].includes(
                                    v.replace(/-/g, '').length
                                ) &&
                                    /^\d+$/.test(v)) ||
                                'A Valid Phone Number Is Required.',
                        ]"
                        ref="recoverPinPhone"
                    ></v-text-field>
                    <v-card-text
                        v-if="recoverPinError"
                        class="pt-0 error--text"
                    >
                        {{ recoverPinError }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="default" @click="recoverPinDialog = false"
                            >Cancel</v-btn
                        >
                        <v-btn
                            class="button"
                            :loading="recoveringPin"
                            @click="recoverPin"
                            >Call Me</v-btn
                        >
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
//import moment from "moment";
import payment from "./Payment.vue";
import LogRocket from "logrocket";
import { formatPhoneNumber, sum } from "../utils/helpers.js";

export default {
    components: {
        payment,
        // MaskedInput
    },
    data() {
        return {
            responsive: false,
            showPaymentView: false,
            processing: false,
            manualPayment: true,

            recoverPinDialog: false,
            recoverPinPhone: "",
            recoveringPin: false,
            recoverPinError: "",
            recoverPinSuccess: false,

            pin: "",
            phoneNumber: "",

            manual: {
                name: "",
                phone: "",
                address: "",
                email: "",
                selectedCampaign: "",
                campaignReason: "",
            },

            donor: null,
            relatedStudents: [],
            transactions: [],
            pinMask: "#####",
            phoneNumberMask: "###-###-####",
            phoneNumberMask2: "###-###-####",

            loading: false,
            notFoundError: "",
            showLoadAccountMessage: false,

            campaigns: [],

            standardRules: [(v) => !!v || "Required."],
            manualRules: [(v) => !!v || "Required."],

            campaignReasonRules: [
                (value) =>
                    !!value ||
                    this.manual.selectedCampaign.name != "Other" ||
                    "Required",
            ],

            emailRules: [
                (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return !value || pattern.test(value) || "Invalid e-mail.";
                },
            ],

            manualPhoneRules: [
                (v) => !!v || "Required.",
                (v) =>
                    !v ||
                    ([10, 11].includes(v.replace(/-/g, "").length) &&
                        /^\d+$/.test(v)) ||
                    "A Valid Phone Number Is Required.",
            ],

            standardPhoneRules: [
                (v) => !!v || this.manualPayment || "Required.",
                (v) =>
                    !v ||
                    ([10, 11].includes(v.replace(/-/g, "").length) &&
                        /^\d+$/.test(v)) ||
                    "A Valid Phone Number Is Required.",
            ],

            headerClass: "",
            responsive: false,

            campaignName: "",
            selectionAmounts: [],
            noteTitle: "",
        };
    },
    watch: {
        manualPayment(newValue) {
            this.donor = null;
            this.relatedStudents = [];
            this.transactions = [];

            if (!newValue) {
                this.$refs.name.resetValidation();
                this.$refs.phone.resetValidation();
                this.$refs.address.resetValidation();

                this.$store.commit("app/setManual", null);
            } else {
                if (this.$refs.pin) {
                    this.$refs.pin.resetValidation();
                    this.$refs.phoneNumber.resetValidation();
                }

                this.$store.commit("app/setDonor", {});
            }
        },
    },
    mounted() {
        this.onResponsiveInverted();
        window.addEventListener("resize", this.onResponsiveInverted);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResponsiveInverted);
    },
    async created() {
        let settings = "";

        if (this.$route.params.id) {
            this.campaignName = this.$route.params.id;

            try {
                settings = await this.loadCampaignSettings();
            } catch (e) {
                this.$router.push({
                    name: "Dashboard",
                });

                return;
            }
        }

        if (this.campaignName) {
            this.manual.selectedCampaign = "Campaign";
            this.selectionAmounts = settings.selectionAmounts;
            this.noteTitle = settings.noteTitle;
        }

        this.$store.commit("app/setCampaignDetails", {
            campaignName: this.campaignName,
            image: this.responsive
                ? settings.mobileImageURL
                : settings.imageURL,
        });

        if (this.$route.params.id && !this.$store.state.app.paymentFields) {
            await this.loadFromParams();
        }

        if (this.$store.state.app.donor) {
            this.donor = this.$store.state.app.donor;
            this.relatedStudents = this.$store.state.app.relatedStudents;
            this.transactions = this.$store.state.app.transactions;
            this.pin = this.$store.state.app.pin
                ? this.$store.state.app.pin
                : this.pin;
            this.phoneNumber = this.$store.state.app.phone;

            this.showPaymentView = true;
        }
        if (this.$store.state.app.manual && this.$store.state.app.manual.name) {
            this.manual.name = this.$store.state.app.manual.name;
            this.manual.phone = this.$store.state.app.manual.phone;
            this.manual.address = this.$store.state.app.manual.address;
            this.manual.selectedCampaign = this.$store.state.app.manual.selectedCampaign;
            this.manual.campaignReason = this.$store.state.app.manual.campaignReason;
            this.manual.email = this.$store.state.app.manual.email;
            this.manualPayment = !this.$store.state.app.donor;

            this.showPaymentView = true;
        }

        await this.loadCampaignNames();
    },
    computed: {
        isMobile() {
            return window.innerWidth < 531;
        },
    },
    mounted() {
        this.onResponsiveInverted();
        window.addEventListener("resize", this.onResponsiveInverted);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResponsiveInverted);
    },
    methods: {
        onResponsiveInverted() {
            if (window.innerWidth < 551) {
                this.responsive = true;
            } else {
                this.responsive = false;
            }
        },
        onResponsiveInverted() {
            if (window.innerWidth < 991) {
                this.responsive = true;
            } else {
                this.responsive = false;
            }
        },
        async loadCampaignNames() {
            const campaigns = await this.$http.get(
                "api/Campaign/GetCampaignNames"
            );

            this.campaigns = campaigns.filter((c) => c.active);
        },
        async loadCampaignSettings() {
            const settings = await this.$http.get(
                "api/Campaign/getCampaignSettings?campaignName=" +
                    this.campaignName
            );

            return settings;
        },
        goBack(event) {
            if (this.showPaymentView) {
                this.showPaymentView = false;

                event.preventDefault();

                return;
            }
        },
        async loadFromParams() {
            try {
                const data = await this.$http.get(
                    "api/account/loadAccountByLinkID?id=" +
                        this.$route.params.id
                );

                this.donor = data.donor;

                this.relatedStudents = data.relatedStudents.map((student) => {
                    if (!data.relatedStudentsPinNumbers) return student;

                    const pin = data.relatedStudentsPinNumbers.find(
                        (p) => p.studentID.trim() == student.studentID.trim()
                    );

                    student.pinNumber = pin ? pin.pinNumber : "";

                    return student;
                });
                this.transactions = data.transactions;
                this.pin = data.pinNumber;

                this.$store.commit("app/setDonor", {
                    donor: data.donor,
                    relatedStudents: data.relatedStudents,
                    transactions: data.transactions,
                    phone: "",
                    pin: this.pin,
                });

                LogRocket.identify(this.getName(), {
                    name: "Mr." + this.getName(),
                });

                this.$store.commit("app/setManual", null);

                this.removeMainBackground();

                this.showPaymentView = true;

                return;
            } catch (e) {
                return;
            }
        },
        async loadAccount() {
            this.manualPayment = false;

            if (
                ![
                    this.$refs.pin.validate(true),
                    this.$refs.phoneNumber.validate(true),
                ].every(Boolean)
            )
                return;

            this.loading = true;

            const phone = this.phoneNumber
                .replace(/-/g, "")
                .substr(this.phoneNumber.length - 10);

            try {
                const data = await this.$http.get(
                    "api/account/loadAccount?pin=" +
                        this.pin +
                        "&phone=" +
                        phone
                );

                this.donor = data.donor;
                this.relatedStudents = data.relatedStudents.map((student) => {
                    if (!data.relatedStudentsPinNumbers) return student;

                    const pin = data.relatedStudentsPinNumbers.find(
                        (p) => p.studentID.trim() == student.studentID.trim()
                    );

                    student.pinNumber = pin ? pin.pinNumber : "";

                    return student;
                });
                this.transactions = data.transactions;

                this.notFoundError = "";
                this.showLoadAccountMessage = false;
                this.loading = false;

                this.$store.commit("app/setDonor", {
                    donor: data.donor,
                    relatedStudents: data.relatedStudents,
                    transactions: data.transactions,
                    phone: this.phoneNumber,
                    pin: this.pin,
                });

                LogRocket.identify(this.getName(), {
                    name: "Mr." + this.getName(),
                });

                this.$store.commit("app/setManual", null);

                this.removeMainBackground();

                this.showPaymentView = true;
            } catch (e) {
                this.notFoundError = e;
                this.donor = null;
                this.relatedStudents = [];
                this.transactions = [];

                this.showLoadAccountMessage = false;

                this.loading = false;
            }
        },
        getPrizeMessage() {
            const message180 =
                "איר קענט אויפפיקן די הערליכע סידי און ביכל פון מסע הקודש ביים דינער אפיס אין";

            if (this.getTotalDonated() >= 500) {
                return [
                    "איר גייט אי’’ה אריין אין די לייכטער גורל",
                    "איר גייט אי’’ה באקומען די ספר נפלא באילנא דחיי אויף די פאסט",
                    message180,
                ];
            } else if (
                this.getTotalDonated() >= 360 &&
                this.getTotalDonated() < 500
            ) {
                return [
                    "איר גייט אי’’ה באקומען די ספר נפלא באילנא דחיי אויף די פאסט",
                    message180,
                ];
            } else if (
                this.getTotalDonated() >= 180 &&
                this.getTotalDonated() < 360
            ) {
                return [message180];
            }

            return null;
        },
        validateUserInfo() {
            const validations = [
                this.$refs.name.validate(true),
                this.$refs.phone.validate(true),
                this.$refs.address.validate(true),
                this.$refs.email.validate(true),
            ];

            if (this.manual.selectedCampaign.name == "Other") {
                validations.push(this.$refs.campaignReason.validate(true));
            }

            return validations.every(Boolean);
        },
        async continueToPayment() {
            this.manualPayment = true;

            if (
                ![
                    this.$refs.name.validate(true),
                    this.$refs.phone.validate(true),
                    this.$refs.address.validate(true),
                    this.$refs.email.validate(true),
                ].every(Boolean)
            )
                return;

            this.removeMainBackground();

            this.showPaymentView = true;

            LogRocket.identify("Mr." + this.manual.name, {
                name: "Mr." + this.manual.name,
            });

            await this.saveVisit();

            this.$store.commit("app/setDonor", {});
        },
        async saveVisit() {
            try {
                await this.$http.post("api/account/saveVisit", {
                    name: this.manual.name,
                    phone: this.formatPhone(this.manual.phone),
                });
            } catch (e) {
                return;
            }
        },
        removeMainBackground() {
            document
                .getElementById("main-background")
                .classList.remove("mainBackground");
        },
        getName() {
            return this.donor.firste + " " + this.donor.laste;
        },
        getRequiredAmount() {
            if (!this.relatedStudents) return 100;

            return this.relatedStudents.some(
                (s) => s.relationship.trim() == "Child"
            )
                ? 180
                : 100;
        },
        getFullNameH() {
            if (!this.donor) return "";

            return (
                this.donor.ytitle +
                " " +
                this.donor.firsth +
                " " +
                this.donor.lasth +
                " " +
                this.donor.ysuff
            );
        },
        getTotalDonated() {
            const donated =
                this.transactions && this.transactions.length
                    ? sum(this.transactions.map((t) => t.amount))
                    : 0;

            return donated.toFixed(2);
        },
        amountStillMissing() {
            const missing = this.getRequiredAmount() - this.getTotalDonated();

            return missing.toFixed(2);
        },
        getFullName() {
            if (!this.donor) return "Mr. " + this.manual.name;

            return (
                this.donor.etitle +
                " " +
                this.donor.firste +
                " " +
                this.donor.laste
            );
        },
        getFullStudentName(student) {
            return student.firstH + " " + student.lasth;
        },
        async validateDonor() {
            if (!this.manualPayment) {
                const validations = [
                    this.$refs.pin.validate(true),
                    this.$refs.phoneNumber.validate(true),
                ];

                const valid = validations.every(Boolean);

                if (!valid) return false;

                if (this.donor) return true;

                await this.loadAccount();

                if (this.donor) return true;

                return false;
            }

            return [
                this.$refs.name.validate(true),
                this.$refs.phone.validate(true),
                this.$refs.address.validate(true),
            ].every(Boolean);
        },
        formatPhone(phone) {
            const value = phone.substr(phone.length - 10);

            return formatPhoneNumber(value);
        },
        openRecoverPinDialog() {
            this.recoverPinSuccess = false;
            this.recoverPinDialog = true;
        },
        async recoverPin() {
            if (!this.$refs.recoverPinPhone.validate(true)) return;

            this.recoveringPin = true;

            try {
                await this.$http.post(
                    "api/account/recoverPin?phone=" +
                        this.recoverPinPhone
                            .replace(/-/g, "")
                            .substr(this.recoverPinPhone.length - 10)
                );

                this.recoveringPin = false;
                this.recoverPinSuccess = true;
            } catch (e) {
                this.recoverPinSuccess = false;
                this.recoverPinError = e;

                this.recoveringPin = false;
            }
        },
    },
};
</script>
<style>
.rtl {
    text-align: right !important;
    direction: rtl;
}
.scrollable {
    overflow-y: auto;
    height: 300px;
}
.login-card {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 5px solid #389466 !important;
    border-radius: 22px !important;
}
.login-button {
    text-align: left !important;
    font: Medium 14px/20px Roboto;
    letter-spacing: 0 !important;
    color: #ffffff !important;
    background: #389466 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 2px #0000003d;
    border-radius: 2px;
    opacity: 1;
}
.login-title {
    text-align: left !important;
    font: Bold 20px/24px Roboto;
    letter-spacing: 0 !important;
    color: #389466 !important;
}
.no-pin-card {
    border: 1px solid #00000030 !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    padding: 20px;
    width: 80%;
}
.call-me-now-button {
    text-align: center !important;
    font: Medium 10px/20px Roboto;
    letter-spacing: 0;
    color: #389466 !important;
    background: #99999933 0% 0% no-repeat padding-box !important;
    border-radius: 2px !important;
}
.green-text {
    color: #389466;
}
.lotto-logo {
    position: absolute;
    top: -92px;
    right: -60px;
    height: 146px;
    width: 146px;
}
.lotto-logo-mobile {
    position: absolute;
    top: -112px;
    right: -60px;
    height: 146px;
    width: 146px;
    margin-left: -73px;
    left: 50%;
}
.deutsch-header {
    background-image: url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/images/loginid140313/a45242d408102da0012181b2f3cc0a7b_1603302692.jpeg");
}
</style>
